import { Observable } from 'rxjs';
import { ShowCustomerResponse } from '../dto/read-customer.dto';
import {
  UpdateCustomerPayload,
  UpdateustomerResponse,
} from '../dto/update-customer.dto';
import { UpsertCustomerContractPayload } from '@domain/services/cutomer-contract/dto/customer-contract-upsert.dto'; 
import { RegisterCustomerPayload, RegisterCustomerResponse } from '../dto/register-customer.dto';
import { SendEmailPayload } from '../dto/send-email.dto';

export abstract class CustomerRepository {
  abstract registerCustomer(
    payload: RegisterCustomerPayload
  ): Observable<RegisterCustomerResponse>;

  abstract updateCustomerContract(
    payload: UpdateCustomerPayload
  ): Observable<UpdateustomerResponse>;

  abstract setContractDataInLocalStorage(
    payload: UpsertCustomerContractPayload
  ): void;

  abstract sendContractEmail(payload: SendEmailPayload): Observable<any>;

  abstract showCustomer(payload: string): Observable<ShowCustomerResponse>;

  abstract hasContractDataInLocalStorage(): boolean;

  abstract getContractDataInLocalStorage(): UpsertCustomerContractPayload | null;

  abstract removeContractDataInLocalStorage(): void;
}
